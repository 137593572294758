<template>
  <!-- 卡片管理 -->
  <card-container
    :title="'卡片管理'"
    :background="tool.getThemeList(theme)"
    :showIcon="showAdd"
  >
    <div v-if="theme != 'YuYin'" class="card_content">
      <div class="desc_left">
        <div class="desc_logo">
          <img
            src="../../assets/image/cardManagement/icon_cardmanagement_balance.png"
            alt=""
          />
        </div>
        <div class="desc_text">
          <div>卡余额/账户余额</div>
          <div class="desc_step">
            {{ userBalance.money }}/{{ userBalance.userMoney }}¥
          </div>
        </div>
      </div>
      <button
				v-if="Boolean(cantRefundBalance)"
				class="desc_right"
				@click="operateHandle(cantRefundBalance)"
			>
				{{ formatStatus(cantRefundBalance) }}
			</button>
			<button
				v-else
				class="desc_right"
				@click="toComponent('personal-packageRecharge')"
			>
				充值
			</button>
    </div>
    <div class="card_content">
      <div class="desc_left">
        <div class="desc_logo">
          <img
            src="../../assets/image/cardManagement/icon_cardmanagement_nickname.png"
            alt=""
          />
        </div>
        <div class="desc_text">
          <div>卡片昵称</div>
          <div class="desc_step">
            昵称:{{
              beforeUpdate.nickname ? beforeUpdate.nickname : "暂未设置昵称"
            }}
          </div>
        </div>
      </div>
      <button class="desc_right" @click="modifyName">修改</button>
    </div>
    <div class="card_content" v-if="isShow === '1'">
      <div class="desc_left">
        <div class="desc_logo">
          <img
            src="../../assets/image/cardManagement/icon_packagerecord_unbound.png"
            alt=""
          />
        </div>
        <div class="desc_text">
          <div>未绑定</div>
          <div class="desc_step">绑定手机号码，管理卡更方便</div>
        </div>
      </div>
      <button
        class="desc_right_step"
        @click="toComponent('personal-bindMobile', 3)"
      >
        绑定
      </button>
    </div>
    <!-- <div class="card_content" v-if="isShow === '2' && operator != 4">
      <div class="desc_left">
        <div class="desc_logo">
          <img
            src="../../assets/image/cardManagement/icon_packagerecord_orderrefund.png"
            alt=""
          />
        </div>
        <div class="desc_text">
          <div>注销卡片</div>
          <div class="desc_step">注销后会无法使用不建议操作</div>
        </div>
      </div>
      <button class="desc_right_step" @click="cancelCard">注销</button>
    </div> -->
    <div class="card_content" v-if="isShow === '2'">
      <div class="desc_left">
        <div class="desc_logo">
          <img
            src="../../assets/image/cardManagement/icon_packagerecord_bound.png"
            alt=""
          />
        </div>
        <div class="desc_text">
          <div>已绑定</div>
          <div class="desc_step">{{ mobile }}点击可解绑</div>
        </div>
      </div>
      <button class="desc_right" @click="toComponent('personal-bindMobile', 4)">
        解绑
      </button>
    </div>
    <!-- 设置昵称 -->
    <van-popup v-model:show="beforeUpdate.show_notice" round>
      <name-container @show="closeHandle"> </name-container>
    </van-popup>
    <!-- 注销卡片弹框 -->
    <code-popup ref="codePopup"> </code-popup>
  </card-container>
</template>

<script>
import { getStore } from "@/utils/store";
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const state = reactive({
      showAdd: true, //新增卡片按钮展示
      card: "", //卡号
      theme: "", //主题名称
      userBalance: {}, //卡余额/账户余额
      beforeUpdate: {
        show_notice: false, //弹框展示
        nickname: "" //昵称
      }, //昵称更新前
      isShow: "1", //绑定项展示 1=>未绑定手机号码 2=>已绑定手机号码
      mobile: "", //绑定的手机号
      operator: "", //运营商
      cantRefundBalance: 0 //退款状态
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    state.cantRefundBalance = Number(route.query.cantRefundBalance) || 0;
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    const methods = {
      //路由跳转
      toComponent(name, pageType) {
        switch (name) {
          case "personal-packageRecharge": //充值
            router.push({
              name,
              query: {
                active: 1,
                type: 0
              }
            });
            break;
          case "personal-bindMobile": //绑定手机号&&解绑手机号
            router.push({
              name,
              query: {
                pageType
              }
            });
            break;
          default:
            break;
        }
      },
			// 退款相关操作
			operateHandle(status){
				router.push({
					name: "batch-refund",
					query:{
						status,
					}
				})
			},
      //修改昵称
      modifyName() {
        //打开修改昵称的弹框组件
        state.beforeUpdate.show_notice = true;
      },
      //注销卡片
      cancelCard() {
        ctx.$refs.codePopup.isShow();
      },
      //关闭注销卡片的按钮展示
      closeHandle(msg) {
        if (msg.nickname === "" || (msg.nickname !== "" && !msg.submit_show)) {
          state.beforeUpdate.show_notice = msg.show_notice;
          return;
        }
        if (msg.nickname !== "" && msg.submit_show) {
          state.beforeUpdate.show_notice = msg.show_notice;
          state.beforeUpdate.nickname = msg.nickname;
        }
      },
      // 获取按钮类型
      formatStatus(status) {
        switch (status) {
          case 1:
            return "申请退款";
          case 2:
            return "待审核";
          case 3:
            return "已驳回";
          default:
            return "申请退款";
        }
      }
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
      //判断运营商
      state.operator = info.operator;
      //获取卡号
      state.card = info.virtualId || info.iccid;
      //新增卡片按钮展示
      if (info.mobile) {
        state.showAdd = true;
      } else {
        state.showAdd = false;
      }
      //用户卡余额/账户余额
      state.userBalance = {
        money: info.money,
        userMoney: info.userMoney
      };
      //用户昵称
      state.beforeUpdate = {
        nickname: info.nickname
      };
      //如果用户绑定过手机号码,则绑定展示赋值为2,且显示绑定的手机号码
      if (info.mobile) {
        state.isShow = "2";
        state.mobile = info.mobile;
      }

      //用户绑定标识
      const userBind = getStore({ name: "userBind" });
      if (userBind) {
        //更改绑定展示
        state.isShow = userBind.isShow;
        // 增加解绑的手机号码提示
        if (userBind.mobile !== "") {
          state.mobile =
            userBind.mobile.toString().substr(0, 3) +
            "****" +
            userBind.mobile.substr(7, 11);
        }
        // 增加输入的昵称
        if (userBind.nickname !== "") {
          state.beforeUpdate.nickname = userBind.nickname;
        }
      }
    });
    return {
      ...toRefs(state),
      ...methods,
      tool
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.card_content {
  width: 690px;
  height: 153px;
  background: #ffffff;
  border-radius: 20px;
  margin: 40px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .desc_left {
    width: 390px;
    display: flex;
    justify-content: space-between;
    .desc_logo img {
      width: 91px;
      height: 91px;
    }
    .desc_text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font: {
        size: 30px;
        weight: 400;
      }
      color: #333333;
      width: 272px;
      .desc_step {
        font: {
          size: 26px;
          weight: 400;
        }
        color: #777777;
      }
    }
  }
  .desc_right,
  .desc_right_step {
    width: 140px;
    height: 65px;
    color: #ffffff;
    border: none;
    @include background_color("background_color1");
    border-radius: 38px;
    text-align: center;
    line-height: 65px;
  }
  .desc_right_step {
    background: #fc992c;
  }
}
.van-nav-bar__right {
  right: 20px !important;
}
</style>
